let isProduction = false;
let isDevelopment = false;
let apiWebEndpoint = "";
let snowplowEndpoint = "";
let newsletterEndpoint = "";
let sentryEndpoint = "";
let heapId = "";
let pendoId = "";

// TODO browser global object `window` will likely not work for mobile. That should be fun!
if (window.location.href.includes("localhost") || window.location.href.includes("portal-cdn-dev.digitalpharmacist.com")) {
	isProduction = false;
	isDevelopment = true;
	apiWebEndpoint = "https://api-web.dev.digitalpharmacist.com/api/v1";
	newsletterEndpoint = "https://api.dev.rxwiki.com/apiman-gateway/dp/newsletter-public/1.0";
	snowplowEndpoint = "sp.dev.digitalpharmacist.com";
	sentryEndpoint = "https://c97c35389a5547559dd215bde946c009@o78252.ingest.sentry.io/5881344";
} else if (window.location.href.includes("portal-cdn-staging.digitalpharmacist.com")) {
	isProduction = false;
	isDevelopment = true;
	apiWebEndpoint = "https://api-web.staging.digitalpharmacist.com/api/v1";
	newsletterEndpoint = "https://api.staging.rxwiki.com/apiman-gateway/dp/newsletter-public/1.0";
	snowplowEndpoint = "sp.staging.digitalpharmacist.com";
	sentryEndpoint = "https://1f82cc90979647f7b2eda9d7c8394ff6@o78252.ingest.sentry.io/5881348";
} else if (window.location.href.includes("portal.digitalpharmacist.com")) {
	isProduction = true;
	isDevelopment = false;
	apiWebEndpoint = "https://api-web.digitalpharmacist.com/api/v1";
	newsletterEndpoint = "https://api.rxwiki.com/apiman-gateway/dp/newsletter-public/1.0";
	snowplowEndpoint = "sp.digitalpharmacist.com";
	sentryEndpoint = "https://fdf1e6b544a64a5bbf6c80554cd6f47d@o78252.ingest.sentry.io/5881349";
	heapId = "816727007";
	pendoId = "4eecb586-05c8-4987-6aea-ae0ac0c1aaae";
}

export const configMap = {
	isProduction,
	isDevelopment,
	snowplowEndpoint,
	apiWebEndpoint,
	newsletterEndpoint,
	sentryEndpoint,
	heapId,
	pendoId,
};

export const stateNameToAbbreviation = (name?: string) => {
	const match = UNITED_STATES_STATES.find((x) => x.title === name);
	return match ? match.value : name;
};

export const UNITED_STATES_STATES = [
	{
		title: "Alabama",
		value: "AL",
	},
	{
		title: "Alaska",
		value: "AK",
	},
	// {
	// 	title: "American Samoa",
	// 	value: "AS",
	// },
	{
		title: "Arizona",
		value: "AZ",
	},
	{
		title: "Arkansas",
		value: "AR",
	},
	{
		title: "California",
		value: "CA",
	},
	{
		title: "Colorado",
		value: "CO",
	},
	{
		title: "Connecticut",
		value: "CT",
	},
	{
		title: "Delaware",
		value: "DE",
	},
	{
		title: "District Of Columbia",
		value: "DC",
	},
	// {
	// 	title: "Federated States Of Micronesia",
	// 	value: "FM",
	// },
	{
		title: "Florida",
		value: "FL",
	},
	{
		title: "Georgia",
		value: "GA",
	},
	// {
	// 	title: "Guam",
	// 	value: "GU",
	// },
	{
		title: "Hawaii",
		value: "HI",
	},
	{
		title: "Idaho",
		value: "ID",
	},
	{
		title: "Illinois",
		value: "IL",
	},
	{
		title: "Indiana",
		value: "IN",
	},
	{
		title: "Iowa",
		value: "IA",
	},
	{
		title: "Kansas",
		value: "KS",
	},
	{
		title: "Kentucky",
		value: "KY",
	},
	{
		title: "Louisiana",
		value: "LA",
	},
	{
		title: "Maine",
		value: "ME",
	},
	// {
	// 	title: "Marshall Islands",
	// 	value: "MH",
	// },
	{
		title: "Maryland",
		value: "MD",
	},
	{
		title: "Massachusetts",
		value: "MA",
	},
	{
		title: "Michigan",
		value: "MI",
	},
	{
		title: "Minnesota",
		value: "MN",
	},
	{
		title: "Mississippi",
		value: "MS",
	},
	{
		title: "Missouri",
		value: "MO",
	},
	{
		title: "Montana",
		value: "MT",
	},
	{
		title: "Nebraska",
		value: "NE",
	},
	{
		title: "Nevada",
		value: "NV",
	},
	{
		title: "New Hampshire",
		value: "NH",
	},
	{
		title: "New Jersey",
		value: "NJ",
	},
	{
		title: "New Mexico",
		value: "NM",
	},
	{
		title: "New York",
		value: "NY",
	},
	{
		title: "North Carolina",
		value: "NC",
	},
	{
		title: "North Dakota",
		value: "ND",
	},
	// {
	// 	title: "Northern Mariana Islands",
	// 	value: "MP",
	// },
	{
		title: "Ohio",
		value: "OH",
	},
	{
		title: "Oklahoma",
		value: "OK",
	},
	{
		title: "Oregon",
		value: "OR",
	},
	// {
	// 	title: "Palau",
	// 	value: "PW",
	// },
	{
		title: "Pennsylvania",
		value: "PA",
	},
	{
		title: "Puerto Rico",
		value: "PR",
	},
	{
		title: "Rhode Island",
		value: "RI",
	},
	{
		title: "South Carolina",
		value: "SC",
	},
	{
		title: "South Dakota",
		value: "SD",
	},
	{
		title: "Tennessee",
		value: "TN",
	},
	{
		title: "Texas",
		value: "TX",
	},
	{
		title: "Utah",
		value: "UT",
	},
	{
		title: "Vermont",
		value: "VT",
	},
	{
		title: "Virgin Islands",
		value: "VI",
	},
	{
		title: "Virginia",
		value: "VA",
	},
	{
		title: "Washington",
		value: "WA",
	},
	{
		title: "West Virginia",
		value: "WV",
	},
	{
		title: "Wisconsin",
		value: "WI",
	},
	{
		title: "Wyoming",
		value: "WY",
	},
];

export enum DeliveryOptions {
	mail = "mail",
	kiosk = "kiosk",
	pickup = "pickup",
	delivery = "delivery",
	curbside = "curbside",
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
export const passwordStrengthMessage = "Must include at least 8 characters, a number, a capital letter, a lowercase letter, and a special character";
